import request from '@/utils/request';

/**
 * 查询所有留言列表
 * @param {Object} data
 */
export function getMsgList(data) {
  return request({
    url: '/hospital-msg-apply/message/getAllList',
    method: 'post',
    data
  });
}

/**
 * 【用户留言】删除留言
 * @param {Object} data
 */
export function delMessage(data) {
  return request({
    url: '/hospital-msg-apply/message/del',
    method: 'post',
    data
  });
}

/**
 * 【医生解答】保存医生解答
 * @param {Object} data
 */
export function saveAnswer(data) {
  return request({
    url: '/hospital-msg-apply/answer/save',
    method: 'post',
    data
  });
}

/**
 * 医生解答】删除操作
 * @param {Object} data
 */
export function delAnswer(data) {
  return request({
    url: '/hospital-msg-apply/answer/del',
    method: 'post',
    data
  });
}

/**
 * 标记状态
 */
export function updateStatus(data) {
  return request({
    url: '/hospital-msg-apply/message/updateStatus',
    method: 'post',
    data
  });
}
