<template>
  <div class="container">
    <el-tabs value="0">
      <el-tab-pane name="0">
        <span slot="label">
          消息数量
          <el-badge :value="list.length" class="item" type="primary">
          </el-badge>
        </span>
      </el-tab-pane>
    </el-tabs>
    <div style="float: left;">
      <el-button icon="el-icon-refresh" @click="onRefresh">刷新</el-button>
    </div>
    <div style="float: right;">
      <el-form
        ref="queryForm"
        :model="query"
        :inline="true"
        label-width="120px"
      >
        <el-form-item prop="name">
          <el-input
            v-model="query.name"
            placeholder="请输入提问者"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item prop="datearray">
          <el-date-picker
            v-model="query.datearray"
            type="daterange"
            :picker-options="queryPickerOptions"
            range-separator="至"
            start-placeholder="留言开始时间"
            end-placeholder="留言结束时间"
            align="right"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="search">
            搜索
          </el-button>
          <el-button icon="el-icon-reset" @click="resetForm('queryForm')">
            重置
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <div style="clear: both;"></div>

    <!--留言区域-->
    <div class="box-card">
      <el-card v-for="item in list" :key="item.id" shadow="always">
        <div class="msg-question">
          <el-badge
            v-if="item.ansStatus === 0"
            value="new"
            class="new-item"
          ></el-badge>
          <div class="msg-question-header">
            问题：<span>{{ item.name }}</span>
            <span>{{ item.createTime }}</span>
            <el-link type="success" @click="updateStatus(item.id)">
              标 记
            </el-link>
            <el-link type="danger" @click="delAllMsg(item.id)">删 除</el-link>
          </div>
          <div class="msg-question-content">{{ item.content }}</div>
        </div>
        <div class="msg-option">
          <el-collapse accordion>
            <el-collapse-item>
              <template slot="title">
                <el-link type="primary">
                  回复({{ item.answersList.length }})
                </el-link>
                <el-link
                  v-if="item.answersList.length !== 0"
                  type="danger"
                  @click.stop.prevent="delAnswer(item.id)"
                >
                  删除
                </el-link>
              </template>
              <div class="msg-form">
                <el-input
                  v-model="content"
                  type="textarea"
                  :autosize="{ minRows: 2, maxRows: 4 }"
                  placeholder="请输入内容"
                  clearable
                ></el-input>
                <el-button
                  type="success"
                  icon="el-icon-check"
                  @click="submitAnswer(item)"
                >
                  提 交
                </el-button>
              </div>
              <div>
                <div
                  v-for="(ans, index) in item.answersList"
                  :key="index"
                  class="msg-question msg-answer"
                >
                  <div class="msg-question-header">
                    <span>{{ ans.answerTitle }}</span>
                    <span>{{ ans.answerTime }}</span>
                    <el-link type="danger" @click="delOne(ans.ansId)">
                      删 除
                    </el-link>
                  </div>
                  <div class="msg-question-content">{{ ans.content }}</div>
                </div>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
      </el-card>
    </div>
    <!--留言区域-->

    <div class="pagination">
      <el-pagination
        :current-page="pageInfo.startPage"
        :page-sizes="[10, 20, 30, 50, 100, 200]"
        :page-size="pageInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageInfo.total"
        @size-change="onSizeChange"
        @current-change="onPageChange"
      />
    </div>
  </div>
</template>
<script>
import {
  getMsgList,
  delMessage,
  saveAnswer,
  delAnswer,
  updateStatus
} from '@/api/message';

export default {
  name: 'HospitalList',
  data() {
    return {
      queryPickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }
        ]
      },
      query: {
        name: '',
        datearray: ''
      },
      content: '',
      pageInfo: {
        startPage: 1,
        pageSize: 20,
        total: 0,
        name: '',
        beginDate: '',
        endDate: ''
      },
      list: [],
      listLoading: true,
      dlgLoading: false,
      exportLoading: false,
      selectDlgShow: false,
      multipleSelection: []
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    onPageChange(page) {
      this.pageInfo.startPage = page;
      this.fetchData();
    },
    onSizeChange(size) {
      this.pageInfo.pageSize = size;
      this.fetchData();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    search() {
      if (this.query.datearray != null) {
        let beginDate = this.query.datearray[0];
        let endDate = this.query.datearray[1];
        this.pageInfo.beginDate = beginDate;
        this.pageInfo.endDate = endDate;
      } else {
        this.pageInfo.beginDate = '';
        this.pageInfo.endDate = '';
      }
      this.pageInfo.name = this.query.name;
      this.fetchData();
    },
    onRefresh() {
      this.fetchData();
    },
    //查询列表
    fetchData() {
      this.listLoading = true;
      getMsgList(this.pageInfo).then(response => {
        this.list = response.data.records;
        this.pageInfo.total = response.data.total;
        this.pageInfo.startPage = response.data.current;
        this.pageInfo.pageSize = response.data.size;
        this.listLoading = false;
      });
    },
    //回复
    submitAnswer(row) {
      let userId = sessionStorage.getItem('userId');
      saveAnswer({
        msgid: row.id,
        content: this.content,
        userid: userId,
        toUserName: row.name
      })
        .then(resp => {
          if (resp.code == 200) {
            this.$message({
              message: '提交成功',
              type: 'success'
            });
            this.content = '';
            this.onRefresh();
          } else {
            this.$message.error(resp.msg);
          }
        })
        .catch(() => {
          console.log('服务器响应失败');
        });
    },
    //删除所有
    delAllMsg(id) {
      this.$confirm(
        '删除该用户留言会将该留言下的所有回复删除, 是否继续?',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      )
        .then(() => {
          delMessage({ id: id })
            .then(resp => {
              if (resp.code === 200) {
                this.$message({
                  type: 'success',
                  message: '删除成功!'
                });
                this.onRefresh();
              } else {
                this.$message.error(resp.msg);
              }
            })
            .catch(() => {
              this.$message.error('网络异常');
            });
        })
        .catch(error => {
          console.log(error);
        });
    },
    //删除所有回复
    delAnswer(id) {
      this.$confirm('您确定删除所有的回复吗, 是否确定?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delAnswer({ msgId: id })
            .then(resp => {
              if (resp.code === 200) {
                this.$message({
                  type: 'success',
                  message: '删除成功!'
                });
                this.onRefresh();
              } else {
                this.$message.error(resp.msg);
              }
            })
            .catch(() => {
              this.$message.error('网络异常');
            });
        })
        .catch(error => {
          console.log(error);
        });
    },
    //删除单个
    delOne(id) {
      this.$confirm('您确定要删除此条消息吗, 是否确定?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delAnswer({ id: id })
            .then(resp => {
              if (resp.code === 200) {
                this.$message({
                  type: 'success',
                  message: '删除成功!'
                });
                this.onRefresh();
              } else {
                this.$message.error(resp.msg);
              }
            })
            .catch(() => {
              this.$message.error('网络异常');
            });
        })
        .catch(error => {
          console.log(error);
        });
    },
    //标记状态
    updateStatus(id) {
      this.$confirm('您确定是否标记此消息为已读, 是否确定?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          updateStatus({ id: id })
            .then(resp => {
              console.log(resp);
              if (resp.code === 200) {
                this.$message({
                  type: 'success',
                  message: '标记成功'
                });
                this.onRefresh();
              } else {
                this.$message.error(resp.msg);
              }
            })
            .catch(() => {
              this.$message.error('网络异常');
            });
        })
        .catch(error => {
          console.log(error);
        });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  }
};
</script>
<style scoped>
.box-card > div {
  margin-bottom: 15px;
}
.msg-question {
  position: relative;
  width: 100%;
  display: inline-block;
}
.msg-question-header {
  font-size: 12px;
  font-weight: bold;
  font-family: '微软雅黑';
}
.msg-question-header span {
  margin-right: 10px;
}
.msg-question-header a {
  float: right;
  margin-right: 10px;
}
.msg-question-content {
  font-size: 14px;
  padding: 10px 0px;
  border-bottom: 1px solid #f2f2f2;
  box-sizing: border-box;
}
.el-collapse-item__wrap {
  background-color: #f2f2f2 !important;
  padding: 10px !important;
}
.msg-option a {
  margin-right: 10px;
}
.msg-answer {
  clear: both;
  border-bottom: 1px solid #d1dbe5;
  background: #f2f2f2;
  padding: 10px;
  box-sizing: border-box;
}
.el-textarea {
  float: left;
  width: 90% !important;
}
.msg-form {
  width: 100%;
  min-height: 65px;
}
.msg-form button {
  margin-left: 10px;
  float: left;
}
.new-item {
  position: absolute;
  top: -18px;
  left: -25px;
  transform: rotateZ(-50deg);
  -webkit-transform: rotateZ(-50deg);
  -moz-transform: rotateZ(-50deg);
  -ms-transform: rotateZ(-50deg);
}
</style>
